const initRudderStack = () => {
  if (window && window.rudderanalytics) {
    window.rudderanalytics.loadJS()
  }
}

const initScripts = () => {
  if (window.scriptsDidInit) {
    return false
  }
  window.scriptsDidInit = true
  initRudderStack()
  // init more (deferred) scripts if needed
  // TODO: Check if some deferred scripts are needed
}

const initScriptsOnEvent = (event) => {
  initScripts()
  event.currentTarget.removeEventListener(event.type, initScriptsOnEvent) // remove the event listener that got triggered
}

export const onClientEntry = () => {
  let initial_page = window.sessionStorage.getItem("initial_page")
  if (!initial_page) {
    window._initial_page = window.location.href
    window.sessionStorage.setItem("initial_page", window._initial_page)
  } else {
    window._initial_page = initial_page
  }
  // TODO: update rudderstack API key and URL
  // !function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},
  //     e.load('27faVpCjbq685Ib6DXonnfPjRD3', 'https://dibbygrzeolg.dataplane.rudderstack.com')
  // }();
}

export const onInitialClientRender = () => {
  document.addEventListener("DOMContentLoaded", () => {
    setTimeout(initScripts, 100)
  })
  document.addEventListener("scroll", initScriptsOnEvent)
  document.addEventListener("mousemove", initScriptsOnEvent)
  document.addEventListener("touchstart", initScriptsOnEvent)
}

const getQueryString = () => {
  const item = window.sessionStorage.getItem("qs")
  if (!item) return {}
  try {
    const qs = JSON.parse(item)
    window.qs = qs
    return qs
  } catch {
    return {}
  }
}

const setQueryString = (qs) => {
  window.sessionStorage.setItem("qs", JSON.stringify(qs))
  window.qs = qs
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  const vars = getQueryString()
  if (location.search) {
    const query = location.search.substring(1)
    query.split("&").forEach((v) => {
      const pair = v.split("=")
      if (pair.length > 1) {
        vars[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
      } else {
        vars[decodeURIComponent(pair[0])] = null
      }
    })
    setQueryString(vars)
  }

  window?.rudderanalytics?.page()
}
